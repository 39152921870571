import React, { useEffect, useState } from "react";

import "./blog.css";

import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Loader from "../../loader/Loader";

const MemberTyp = localStorage.getItem("MemberType");

const Blog = () => {
  const [isLoader, setisLoader] = useState(false);

  console.log("Member Type : ", MemberTyp);

  return (
    <>
      <Navbar MemberType={MemberTyp} />
      {isLoader && <Loader />}
      <div className="container">
        <h1>Hello Ali</h1>
      </div>
      {!isLoader && <Footer />}
    </>
  );
};

export default Blog;
