import React, { useEffect, useState } from "react";

import "./faq.css";

import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Loader from "../../loader/Loader";

const MemberTyp = localStorage.getItem("MemberType");

const categories = [
  { _id: "5f8d0d55b54764421b7156c1", name: "General" },
  { _id: "5f8d0d55b54764421b7156c2", name: "Installation" },
  { _id: "5f8d0d55b54764421b7156c3", name: "Pricing" },
  { _id: "5f8d0d55b54764421b7156c4", name: "Support" },
];

const faqs = [
  {
    question: "What is Readmax?",
    answer:
      "Readmax is a browser extension that enhances your reading experience by providing various tools and features.",
    category: "5f8d0d55b54764421b7156c1",
  },
  {
    question: "How do I install the Readmax extension?",
    answer:
      "You can install the Readmax extension from the Chrome Web Store or by visiting our official website.",
    category: "5f8d0d55b54764421b7156c2",
  },
  {
    question: "Is Readmax free to use?",
    answer:
      "Yes, Readmax offers a free version with basic features. There is also a premium version with additional features.",
    category: "5f8d0d55b54764421b7156c3",
  },
  {
    question: "How can I contact Readmax support?",
    answer:
      "You can contact Readmax support through our support page or by emailing support@readmax.com.",
    category: "5f8d0d55b54764421b7156c4",
  },
  {
    question: "Is there a mobile app for Readmax?",
    answer:
      "Yes, Readmax is available on iOS, Android, and web platforms. You can download the mobile app from the App Store or Google Play, and access the web version through your browser.",
    category: "5f8d0d55b54764421b7156c1",
  },
];

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Faq = () => {
  const [isLoader, setisLoader] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log("Value : ", newValue);
    setValue(newValue);
  };

  console.log("Member Type : ", MemberTyp);

  return (
    <>
      <Navbar MemberType={MemberTyp} />
      {isLoader && <Loader />}
      <div className="main-container">
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: 224,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Item One" {...a11yProps(99)} />
            <Tab label="Item Two" />
            <Tab label="Item Three" />
            <Tab label="Item Four" />
            <Tab label="Item Five" />
            <Tab label="Item Six" />
            <Tab label="Item Seven" />
          </Tabs>
          <TabPanel value={value} index={88}>
            Item One
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
          <TabPanel value={value} index={3}>
            Item Four
          </TabPanel>
          <TabPanel value={value} index={4}>
            Item Five
          </TabPanel>
          <TabPanel value={value} index={5}>
            Item Six
          </TabPanel>
          <TabPanel value={value} index={6}>
            Item Seven
          </TabPanel>
        </Box>
      </div>
      {!isLoader && <Footer />}
    </>
  );
};

export default Faq;
